import type { SVGProps } from "react";
const IconBrandXFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="m13.79 10.47 6.13-7.22h-1.97l-5.08 5.99-4.49-5.99H2.5l7.24 9.66-6.66 7.84h1.97l5.62-6.61 4.95 6.61h5.88z"
    />
  </svg>
);
export default IconBrandXFilled;
