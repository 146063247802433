import type { SVGProps } from "react";
const IconAngleDownSmallRegular = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.0003 15.0003C11.7443 15.0003 11.4883 14.9023 11.2933 14.7073L7.29325 10.7073C6.90225 10.3163 6.90225 9.68425 7.29325 9.29325C7.68425 8.90225 8.31631 8.90225 8.70731 9.29325L12.0003 12.5862L15.2933 9.29325C15.6842 8.90225 16.3163 8.90225 16.7073 9.29325C17.0983 9.68425 17.0983 10.3163 16.7073 10.7073L12.7073 14.7073C12.5123 14.9023 12.2563 15.0003 12.0003 15.0003Z"
      fill="currentColor"
    />
  </svg>
);
export default IconAngleDownSmallRegular;
