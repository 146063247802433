import type { SVGProps } from "react";
const IconBrandPinterestFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M22 12c0 5.52-4.48 10-10 10-1.03 0-2.01-.16-2.95-.44.41-.65 1.06-1.79 1.3-2.7l.62-2.36c.32.62 1.27 1.14 2.28 1.14 3 0 5.16-2.76 5.16-6.19S15.73 5.7 12.28 5.7c-4.3 0-6.58 2.88-6.58 6.02 0 1.46.78 3.28 2.02 3.86.19.09.29.05.33-.13.03-.14.2-.82.28-1.13.03-.1 0-.21-.07-.29-.41-.5-.74-1.41-.74-2.27 0-2.2 1.66-4.32 4.49-4.32 2.45 0 4.16 1.67 4.16 4.05 0 2.69-1.36 4.56-3.13 4.56-.97 0-1.71-.81-1.47-1.8.28-1.18.82-2.46.82-3.31 0-.77-.41-1.41-1.26-1.41-1 0-1.8 1.04-1.8 2.42 0 .88.3 1.48.3 1.48s-.99 4.17-1.17 4.95c-.21.9-.11 2.18-.02 2.96C4.68 19.9 2 16.27 2 12 2 6.48 6.48 2 12 2s10 4.48 10 10"
    />
  </svg>
);
export default IconBrandPinterestFilled;
