import HeadAndMeta, { type LinkPreviewType } from "@/components/OldHeadAndMeta";
import Text from "@/components/v2/atoms/Text/Text";
import { SourceType } from "@/generated/requests/pos";
import classNames from "classnames";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { type ReactNode, useEffect, useState } from "react";
import IconShoppingBagAltFilled from "../../atoms/Icons/ShoppingBagAltFilled";
import { SlideOut } from "../../atoms/SlideOut/SlideOut";
import { useLayoutContext } from "../../contexts/LayoutContext/LayoutContext";
import { useOrderContextNew } from "../../contexts/OrderContextNew/OrderContextNew";
import Button from "../../molecules/Button/Button";
import ButtonLink from "../../molecules/ButtonLink/ButtonLink";
import Cart from "../../organisms/Cart/Cart";
import Footer from "../../organisms/Footer/Footer";
import Header, { type HeaderVariant } from "../../organisms/Header/Header";
import SiteNav from "../../organisms/SiteNav/SiteNav";

interface BasicLayoutProps {
  overrideOpenGraphTitle?: string;
  overrideOpenGraphDescription?: string;
  overrideOpenGraphImage?: string;
  overrideOpenGraphDisplayType?: LinkPreviewType;
  overrideOpenGraphVideo?: string;
  header?: boolean;
  headerVariant?: HeaderVariant;
  headerFade?: boolean;
  headerPadding?: boolean;
  cta?: boolean;
  footer?: boolean;
  orderFromLocationPage?: boolean;
  children: ReactNode;
  hideFullWidth?: boolean;
  storeName?: string;
}

/**
 * z-index usage
 *
 * A general note on z-index layers. Based on the provided classes from
 * tailwind.css, the following should be respected:
 *    • z-0, base layer
 *    • z-30, navbar layer
 *    • z-40, slide out menus
 *    • z-50, overlay content (rare, use sparingly)
 *
 * Most content should work with a `z-10` or `z-20` to layer below the navbar.
 * Use arbitrary values if required to layer between the higher layers (i.e.
 * `z-[31]`).
 */

/**
 * max-width
 *
 * Foreground content should never exceed `max-w-9xl`, but we can't enforce it
 * layout wide without affecting background content. It is the responsibility
 * of the coder to make sure components do not exceed this max-width when
 * building page content. FYI `max-w-9xl` is arbitrary – it's basically the
 * max width of my 16" MacBook pro and figured it was a decent max.
 */

export default function BasicLayout({
  overrideOpenGraphTitle = null,
  overrideOpenGraphDescription = null,
  overrideOpenGraphImage = null,
  overrideOpenGraphDisplayType = null,
  overrideOpenGraphVideo = null,
  header = true,
  headerVariant = "primary",
  headerFade = false,
  cta = true,
  footer = true,
  orderFromLocationPage = false,
  children,
  hideFullWidth = false,
  storeName = null,
}: BasicLayoutProps) {
  const { t } = useTranslation();
  const router = useRouter();
  const { order } = useOrderContextNew();
  const { showSiteNav, setShowSiteNav, showCart, setShowCart, showCta, hasOrder } = useLayoutContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAnimate, setIsAnimate] = useState<boolean>(false);
  const totalItemsInCart = order?.items?.reduce((total, item) => total + item?.quantity, 0) || 0;
  const totalItemsInCartDisplay = Math.min(99, totalItemsInCart);
  const isOrderFlow = router?.isReady && router.pathname.match(/^\/order/);
  const isCheckoutPage = router?.isReady && router.pathname.match(/^\/order(.*)?\/checkout/);
  const storeSlug = router.query.slug as string;
  const orderType = router.query.type as string;

  useEffect(() => {
    showCart || showSiteNav
      ? document.body.classList.add("overflow-hidden")
      : document.body.classList.remove("overflow-hidden");
  }, [showCart, showSiteNav]);

  useEffect(() => {
    setIsAnimate(true);
    setTimeout(() => {
      setIsAnimate(false);
    }, 1000);
  }, [order?.items?.length]);

  useEffect(() => {
    if (isCheckoutPage) {
      setShowCart(false);
    }
  }, [router?.pathname]);

  const handleClickOrderNow = async () => {
    if (orderFromLocationPage) {
      setIsLoading(true);
      try {
        // get store by slug
        const { slug } = router?.query;
        router.push(`/order/${SourceType.CarryOut.toLowerCase()}/${slug}`);
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    } else {
      router.push("/order");
    }
  };

  const handleCartDismiss = () => {
    if (!document.body.classList.contains("update-gift-wrap-modal-open")) {
      setShowCart(false);
    }
  };

  return (
    <>
      <HeadAndMeta
        overrideOpenGraphTitle={overrideOpenGraphTitle}
        overrideOpenGraphDescription={overrideOpenGraphDescription}
        overrideOpenGraphImage={overrideOpenGraphImage}
        overrideOpenGraphDisplayType={overrideOpenGraphDisplayType}
        overrideOpenGraphVideo={overrideOpenGraphVideo}
        storeName={storeName}
      />
      {(!!showSiteNav || !!showCart) && (
        <div
          role="invisible page cover when slideout is open to allow for clicking out of the slideout without interacting with the page"
          className="fixed inset-0 w-screen h-screen z-40"
        ></div>
      )}
      <SlideOut visible={!!showSiteNav} from="left" onDismiss={() => setShowSiteNav(false)} className="w-full max-w-96">
        <SiteNav onDismiss={() => setShowSiteNav(false)} />
      </SlideOut>
      {!!showCart && !!order?.items?.length && (
        <div className="fixed inset-0 w-screen h-screen bg-gray-500 opacity-50 z-30"></div>
      )}
      <SlideOut
        visible={!!showCart && !!order?.items?.length}
        from="right"
        onDismiss={handleCartDismiss}
        className="w-full sm:max-w-[465px] hidden lg:block"
      >
        <Cart onDismiss={handleCartDismiss} />
      </SlideOut>
      {header && (
        <Header
          variant={headerVariant}
          fade={headerFade}
          callToAction={
            cta ? (
              <>
                {!!hasOrder && (
                  <div className="relative hidden sm:block">
                    <div
                      className={classNames(
                        "w-[16px] h-[16px] bg-[#EB0000] rounded-full absolute top-[8px] left-[20px] flex items-center justify-center text-xs text-white",
                        { "animate-bounce": isAnimate },
                      )}
                    >
                      {totalItemsInCartDisplay}
                    </div>
                    {/* We are doing two buttons here so that we get nicer behavior with swipe navigation on mobile devices, mobile will take you to a new screen and desktop will do modals still */}
                    <div className="lg:block hidden">
                      <Button
                        variant="primary"
                        contentLeft={<IconShoppingBagAltFilled className="text-white size-8" />}
                        className={classNames("invisible sm:visible mr-[22px] focus:ring-dark-blue", {
                          "animate-bounce": isAnimate,
                        })}
                        onClick={() => {
                          setShowCart(true);
                        }}
                      >
                        {t("order:view_bag")}
                      </Button>
                    </div>
                    <div className="lg:hidden">
                      <Button
                        variant="primary"
                        contentLeft={<IconShoppingBagAltFilled className="text-white size-8" />}
                        className={classNames("invisible sm:visible mr-[22px]", {
                          "animate-bounce": isAnimate,
                        })}
                        onClick={() => {
                          router.push(`/order/${orderType}/${storeSlug}/cart`);
                        }}
                      >
                        {t("order:view_bag")}
                      </Button>
                    </div>
                  </div>
                )}
                {!hasOrder && !isOrderFlow && (
                  <Button
                    variant="primary"
                    onClick={handleClickOrderNow}
                    className="invisible sm:visible mr-[22px]"
                    disabled={isLoading}
                  >
                    {t("order:order_now")}
                  </Button>
                )}
              </>
            ) : null
          }
          onMenuButtonClicked={() => setShowSiteNav(true)}
        />
      )}
      {/*
        Set all text to be Crumbl Sans here, once we finish the rebrand, we
        can update the tailwind config to use it by default.
      */}
      <div
        className={classNames(header && !headerFade && "pt-[60px] sm:pt-[80px]", "w-full flex flex-col items-center")}
      >
        <div className={classNames("sticky top-0 w-full", { "max-w-screen-2xl": !hideFullWidth })}>{children}</div>
        {hasOrder && cta && showCta && (
          <div
            className={classNames("fixed bottom-[30px] right-[30px] sm:hidden rounded-full h-[50px]", {
              "w-[calc(100%-60px)]": isOrderFlow,
              "w-[50px]": !isOrderFlow,
            })}
          >
            {/* We are doing two buttons here so that we get nicer behavior with swipe navigation on mobile devices, mobile will take you to a new screen and desktop will do modals still */}
            <Button
              onClick={() => setShowCart(true)}
              className={classNames("h-full w-full justify-between z-30 lg:block hidden", {
                "animate-bounce": isAnimate,
              })}
            >
              <div
                className={classNames("w-[28px] h-[28px] absolute top-[10px] flex items-center justify-center", {
                  "right-[20px]": isOrderFlow,
                  "right-[10px]": !isOrderFlow,
                })}
              >
                <IconShoppingBagAltFilled className={classNames("text-white size-7")} />
                <div
                  className={classNames(
                    "w-[14px] h-[14px] bg-[#FF4B4B] rounded-full absolute top-[0px] right-[0px] flex items-center justify-center text-xs text-white",
                  )}
                >
                  <span className="text-[10px] leading-[0px] relative top-[-1px]">{totalItemsInCartDisplay}</span>
                </div>
              </div>
              {!!isOrderFlow && <Text variant="lineItem">{t("order:view_bag")}</Text>}
            </Button>
            <ButtonLink
              href={`/order/${orderType}/${storeSlug}/cart`}
              className={classNames("h-full w-full justify-between z-30 lg:hidden", {
                "animate-bounce": isAnimate,
              })}
            >
              <div
                className={classNames("w-[28px] h-[28px] absolute top-[10px] flex items-center justify-center", {
                  "right-[20px]": isOrderFlow,
                  "right-[10px]": !isOrderFlow,
                })}
              >
                <IconShoppingBagAltFilled className={classNames("text-white size-7")} />
                <div
                  className={classNames(
                    "w-[14px] h-[14px] bg-[#FF4B4B] rounded-full absolute top-[0px] right-[0px] flex items-center justify-center text-xs text-white",
                  )}
                >
                  <span className="text-[10px] leading-[0px] relative top-[-1px]">{totalItemsInCartDisplay}</span>
                </div>
              </div>
              {!!isOrderFlow && <Text variant="lineItem">{t("order:view_bag")}</Text>}
            </ButtonLink>
          </div>
        )}
      </div>
      {footer && <Footer />}
    </>
  );
}
