import classNames from "classnames";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { type HTMLProps, type ReactNode, useEffect, useRef } from "react";
import IconMenuLeftAltFilled from "../../atoms/Icons/MenuLeftAltFilled";
import IconWordmark from "../../atoms/Icons/Wordmark";
import Text from "../../atoms/Text/Text";

const variants = {
  primary: "bg-primary",
  light: "bg-white",
} as const;

export type HeaderVariant = keyof typeof variants;
export const allVariants = Object.keys(variants) as HeaderVariant[];

export interface HeaderProps extends HTMLProps<HTMLElement> {
  variant?: HeaderVariant;
  fade?: boolean;
  onMenuButtonClicked?: Function;
  callToAction?: ReactNode;
}

export default function Header({ variant = "primary", fade = false, onMenuButtonClicked, callToAction }: HeaderProps) {
  const bgRef = useRef<HTMLDivElement>();
  const ctaRef = useRef<HTMLDivElement>();
  const heightStyles = "h-[60px] sm:h-[80px]";
  const fadeStyles = "opacity-0";
  const { t } = useTranslation();

  useEffect(() => {
    let ticking = false;
    let scrollPosition = 0;

    const setOpacity = (opacity) => {
      if (bgRef.current) {
        bgRef.current.style.opacity = `${opacity}`;
      }
      if (ctaRef.current) {
        ctaRef.current.style.opacity = `${opacity}`;
      }
    };

    const scrollHandler = () => {
      scrollPosition = window.scrollY;
      if (!ticking) {
        ticking = true;
        window.requestAnimationFrame(() => {
          const opacity = Math.min(1.0, scrollPosition / 120);
          setOpacity(opacity);
          ticking = false;
        });
      }
    };

    if (fade) {
      document.addEventListener("scroll", scrollHandler);
    } else {
      setOpacity("");
    }

    return () => {
      document.removeEventListener("scroll", scrollHandler);
    };
  }, [fade]);

  const handleMenuButtonClicked = () => {
    onMenuButtonClicked?.();
  };

  return (
    <>
      {/* Background layer with call-to-action */}
      <div
        ref={bgRef}
        className={classNames(
          "fixed left-0 right-0 z-30 shadow-sm",
          heightStyles,
          variants[variant],
          fade && fadeStyles,
        )}
      />

      {/* Foreground layer */}
      <div className={classNames("fixed z-30 w-1/2 max-w-[54rem] right-1/2", heightStyles)}>
        <div className="flex items-center justify-between py-2 pl-2 h-full">
          <div className="flex items-center justify-start">
            <button
              className="flex items-center justify-start sm:ml-[22px] focus:ring-dark-blue"
              onClick={handleMenuButtonClicked}
            >
              <div className="flex items-center gap-[10px]">
                <IconMenuLeftAltFilled className="size-6 lg:size-[30px]" />
                <Text variant="title2" className="hidden sm:inline-flex">
                  {t("common:menu")}
                </Text>
              </div>
            </button>
          </div>
          <div className="flex items-center justify-center">
            <Link
              href="/"
              className="-mr-[52.5px] lg:-mr-[77.5px] focus:ring-dark-blue"
              aria-label={t("common:crumbl_logo_description")}
            >
              <IconWordmark className="h-[29px] w-[105px] lg:h-[43px] lg:w-[155px]" />
            </Link>
          </div>
        </div>
      </div>

      {/* Call-to-action layer */}
      <div
        ref={ctaRef}
        className={classNames(
          "fixed z-30 flex items-center justify-end p-2",
          "w-1/2 max-w-[54rem] left-1/2",
          heightStyles,
          fade && fadeStyles,
        )}
      >
        {callToAction}
      </div>
    </>
  );
}
