import type { DeepPartial } from "@apollo/client/utilities";
import classNames from "classnames";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import { useState } from "react";
import {
  type OrderItem,
  type ProductModifierOption,
  ProductModifierSpecialSubtype,
  type SourceProduct,
} from "../../../../generated/requests/pos";
import { ImagesV2 } from "../../../../public/images/all";
import { formatMoney } from "../../../../static/lib/util";
import Text from "../../atoms/Text/Text";
import type { ClientOrderItem } from "../../contexts/OrderContext/helpers";
import { useOrderContextNew } from "../../contexts/OrderContextNew/OrderContextNew";
import { useRewardsContext } from "../../contexts/RewardsContext/RewardsContext";
import { getUniqueItemId } from "../../contexts/OrderContextNew/helpers";
import Button from "../../molecules/Button/Button";
import Modal from "../../molecules/Modal/Modal";
import { useGetSource } from "../../operations/queries";
import giftwrapBox from "@/images/v2/giftwrap-illustration-box.png";
import giftwrapBow from "@/images/v2/giftwrap-illustration-bow.png";
import banIcon from "@/images/v2/ban.svg";
import crumblGiftBag from "@/images/v2/crumbl-gift-bag.svg";

interface AddGiftWrapModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedProduct: DeepPartial<SourceProduct>;
  onAddToBag?: (
    selectedGiftWrapModifier: ProductModifierOption,
    selectedGiftBagModifier?: ProductModifierOption,
  ) => void;
  setIsThisAGift?: (isThisAGift: boolean) => void;
}

export function AddGiftWrapModal({
  isOpen,
  onClose,
  selectedProduct,
  onAddToBag,
  setIsThisAGift,
}: AddGiftWrapModalProps) {
  return (
    <GiftWrapModal
      isOpen={isOpen}
      onClose={onClose}
      selectedProduct={selectedProduct}
      onAddToBag={onAddToBag}
      setIsThisAGift={setIsThisAGift}
    />
  );
}

interface UpdateGiftWrapModalProps {
  isOpen: boolean;
  onClose?: () => void;
  itemToUpdate?: OrderItem;
  productForItem?: DeepPartial<SourceProduct>;
  isUpdate?: boolean;
}

export function UpdateGiftWrapModal({ itemToUpdate, isOpen, onClose, isUpdate }: UpdateGiftWrapModalProps) {
  return <GiftWrapModal itemToUpdate={itemToUpdate} isOpen={isOpen} onClose={onClose} isUpdate={isUpdate} />;
}

interface GiftWrapModalProps {
  index?: number;
  itemToUpdate?: OrderItem;
  selectedProduct?: DeepPartial<SourceProduct>;
  isOpen: boolean;
  onAddGiftWrap?: (giftWrapModifier: ProductModifierOption) => void;
  onRemoveGiftWrap?: (orderItemIndex: number, itemToUpdate: ClientOrderItem) => void;
  onUpdateSelection?: (
    orderItemIndex: number,
    itemToUpdate: ClientOrderItem,
    giftWrapModifier: ProductModifierOption,
  ) => void;
  giftWrapOptions?: ProductModifierOption[];
  hideCloseButton?: boolean;
  onClose?: () => void;
  initialGiftWrapOption?: ProductModifierOption;
  isUpdate?: boolean;
  backClickHandler?: () => void;
  onAddToBag?: (
    selectedGiftWrapModifier: ProductModifierOption,
    selectedGiftBagModifier?: ProductModifierOption,
  ) => void;
  setIsThisAGift?: (isThisAGift: boolean) => void;
}

const GiftWrapModal = ({
  itemToUpdate,
  selectedProduct,
  isOpen,
  hideCloseButton = false,
  onClose,
  isUpdate,
  onAddToBag,
  setIsThisAGift,
}: GiftWrapModalProps) => {
  const { t } = useTranslation();
  const { order, orderTimeSlot, upsertOrderItems } = useOrderContextNew();
  const [isLoading, setIsLoading] = useState(false);
  const { data: sourceProductsData } = useGetSource({
    storeId: order?.storeId,
    type: order?.source?.type,
    timeSlot: orderTimeSlot,
  });

  let product: DeepPartial<SourceProduct> = selectedProduct;
  if (!product) {
    product = sourceProductsData?.public?.sourceForStore?.products?.find(
      (p) => p?.product?.productId === itemToUpdate?.product?.productId,
    );
  }
  // #region Gift Wrap
  const giftWrapModifier = product?.product?.modifiers?.find((m) =>
    m?.specialSubtypes?.includes(ProductModifierSpecialSubtype.Giftwrapping),
  );
  const giftWrapOptionInCart = giftWrapModifier?.options?.find(
    (o) =>
      o.optionId ===
      order?.items
        ?.find((item) => getUniqueItemId(item) === getUniqueItemId(itemToUpdate))
        ?.modifiers?.find((m) => m?.specialSubtypes?.includes(ProductModifierSpecialSubtype.Giftwrapping))?.options?.[0]
        ?.modifierOptionId,
  ) as ProductModifierOption | undefined;

  const [selectedGiftWrapOption, setSelectedGiftWrapOption] = useState<ProductModifierOption | undefined>(
    giftWrapOptionInCart,
  );
  // #endregion

  // #region Gift Bag
  const giftBagModifier = product?.product?.modifiers?.find((m) =>
    m?.specialSubtypes?.includes(ProductModifierSpecialSubtype.Giftbag),
  );
  const giftBagOptionInCart = giftBagModifier?.options?.find(
    (o) =>
      o.optionId ===
      order?.items
        ?.find((item) => getUniqueItemId(item) === getUniqueItemId(itemToUpdate))
        ?.modifiers?.find((m) => m?.specialSubtypes?.includes(ProductModifierSpecialSubtype.Giftbag))?.options?.[0]
        ?.modifierOptionId,
  ) as ProductModifierOption | undefined;

  const [selectedGiftBagOption, setSelectedGiftBagOption] = useState<ProductModifierOption | undefined>(
    giftBagOptionInCart,
  );
  // #endregion

  const [prevSelectedGiftWrapOption, setPrevSelectedGiftWrapOption] = useState<ProductModifierOption | undefined>(
    giftWrapOptionInCart,
  );

  // will need to add this when more gift bag options are available
  // const [prevSelectedGiftBagOption, setPrevSelectedGiftBagOption] = useState<ProductModifierOption | undefined>(
  //   giftBagOptionInCart,
  // );

  const handleUpsertGiftOptions = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    try {
      if (isUpdate) {
        const updatedModifiers = [
          // filter out matched modifiers
          ...itemToUpdate.modifiers.filter(
            (modifier) =>
              modifier.modifierId !== giftWrapModifier?.modifierId &&
              modifier.modifierId !== giftBagModifier?.modifierId,
          ),
          // Add new/updated modifiers
          ...(selectedGiftWrapOption
            ? [{ modifierId: giftWrapModifier?.modifierId, options: [selectedGiftWrapOption] }]
            : []),
          ...(selectedGiftBagOption
            ? [{ modifierId: giftBagModifier?.modifierId, options: [selectedGiftBagOption] }]
            : []),
        ];

        await upsertOrderItems({
          order: order,
          items: [
            ...(order?.items?.map((item) => {
              if (getUniqueItemId(item) === getUniqueItemId(itemToUpdate)) {
                return {
                  ...item,
                  modifiers: updatedModifiers,
                };
              }
              return item;
            }) || []),
          ],
        });
      } else {
        // add gift wrap and product to order
        await onAddToBag(selectedGiftWrapOption, selectedGiftBagOption);
      }
      onClose?.();
    } catch (error) {
      console.error("Error updating gift wrap: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNoGiftOptionsClick = () => {
    if (isUpdate) {
      handleUpsertGiftOptions();
    } else {
      setIsThisAGift(false);
      onClose();
    }
  };

  return (
    <Modal
      variant="primaryLight"
      isOpen={isOpen}
      onClose={onClose}
      hideCloseButton={hideCloseButton}
      className="!p-0 flex flex-col"
      title={
        <div className="flex flex-col items-center gap-2 pb-2">
          <Text variant="title1">{t("order:gift_wrap_capital")}</Text>
          <div
            className={`flex justify-center items-center h-52 ${selectedGiftBagOption ? "mx-10 sm:!mx-[100px]" : "mx-10 sm:!mx-[150px]"}`}
          >
            <Image
              src={crumblGiftBag}
              alt="giftwrap-with-bag"
              className={`
                z-10 -rotate-12
                flex-shrink-0
                py-5 sm:py-0
                transform transition-all duration-300 ease-in-out
                ${selectedGiftBagOption ? "translate-x-0 opacity-100 w-[35%] sm:w-[40%]" : "-translate-x-[200px] opacity-0 w-0"}
              `}
            />
            <div
              className={classNames("relative transform transition-all duration-300 top-3", {
                "rotate-0": !selectedGiftBagOption,
                "rotate-12": selectedGiftBagOption,
              })}
            >
              <Image src={giftwrapBox} alt="giftwrap-with-box" height={250} width={250} className="w-full h-auto" />
              <Image
                className={`
                absolute transform transition-all duration-300 ease-in-out
                ${selectedGiftWrapOption ? "translate-x-0 opacity-100" : "-translate-x-full opacity-0"}
                ${
                  selectedGiftBagOption
                    ? "w-[100px] sm:w-[150px] h-auto top-[-35px] left-[-35px] sm:top-[-55px] sm:left-[-55px]"
                    : "w-[150px] sm:w-[200px] h-auto top-[-55px] left-[-55px] sm:top-[-70px] sm:left-[-75px]"
                }
                motion-reduce:transition-none
              `}
                width={250}
                height={250}
                src={giftwrapBow}
                alt="giftwrap-with-bow"
              />
              <Image
                width={100}
                height={100}
                className={`
                absolute top-0 right-0 sm:top-1 sm:right-1
                transform transition-all duration-300 ease-in-out
                ${selectedGiftWrapOption?.image ? "translate-x-0 opacity-100" : "translate-x-full opacity-0"}
                ${selectedGiftBagOption ? "w-[40px] sm:w-[50px] h-auto" : "w-[50px] sm:w-[65px] h-auto"}
                motion-reduce:transition-none
              `}
                src={selectedGiftWrapOption?.image || prevSelectedGiftWrapOption?.image || ImagesV2.blackCircle}
                alt="giftwrap-image"
              />
              <div
                className={`absolute top-0 right-0 sm:top-2 sm:right-2 transform transition-all duration-300 ease-in-out 
                  ${!!selectedGiftWrapOption?.name && !selectedGiftWrapOption?.image ? "translate-x-0 opacity-100" : "translate-x-full opacity-0"}`}
              >
                <div className="relative">
                  <Image
                    width={65}
                    height={65}
                    className="hidden sm:block "
                    src={ImagesV2.blackCircle}
                    alt="giftwrap-image"
                  />
                  <Image
                    width={50}
                    height={50}
                    className="block sm:hidden "
                    src={ImagesV2.blackCircle}
                    alt="giftwrap-image"
                  />
                  <Text
                    className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center text-white text-wrap scale-75 sm:scale-100"
                    variant="caption"
                  >
                    {selectedGiftWrapOption?.name}
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    >
      <div className="flex flex-col my-auto p-[15px] bg-white flex-1 justify-between gap-y-[50px]">
        <div className="flex flex-col gap-y-[40px]">
          <ModifierOptionSection
            itemIndex={1}
            title={t("order:select_gift_wrap")}
            subTitle={t("order:gift_wrap_comes_with_bow")}
            options={giftWrapModifier?.options}
            selectedOption={selectedGiftWrapOption}
            setSelectedOption={(option) => {
              setSelectedGiftWrapOption(option);
              setTimeout(() => {
                setPrevSelectedGiftWrapOption(option);
              }, 300);
            }}
            isLoading={isLoading}
            noOptionText={t("order:no_gift_wrap")}
          />
          {!!giftBagModifier && (
            <ModifierOptionSection
              itemIndex={2}
              title={t("order:add_gift_bag")}
              subTitle={t("order:wrap_your_gift_in_bag")}
              options={giftBagModifier?.options}
              selectedOption={selectedGiftBagOption}
              setSelectedOption={setSelectedGiftBagOption}
              isLoading={isLoading}
              noOptionText={t("order:no_gift_bag")}
            />
          )}
        </div>

        <div
          className={classNames("flex flex-col w-full gap-3 items-center sm:mt-auto py-[15px]", {
            "opacity-50": isLoading,
          })}
        >
          {selectedGiftBagOption || selectedGiftWrapOption ? (
            <Button
              id="addGiftWrap"
              block
              onClick={() => handleUpsertGiftOptions()}
              disabled={!selectedGiftWrapOption && !selectedGiftBagOption}
            >
              {!isUpdate
                ? t("order:add_gift_wrap", {
                    x: selectedGiftWrapOption?.price ? `(+ ${formatMoney(selectedGiftWrapOption?.price)})` : "",
                  })
                : t("order:update_selection")}
            </Button>
          ) : (
            <Button
              id="no_gift_wrap"
              className="inline-flex"
              variant="secondary"
              block
              onClick={handleNoGiftOptionsClick}
            >
              {isUpdate ? t("order:remove_gift_wrap") : t("order:no_gift_wrap")}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

function ModifierOptionSection({
  itemIndex,
  title,
  subTitle,
  options,
  selectedOption,
  setSelectedOption,
  isLoading,
  noOptionText,
}) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-start gap-y-[10px]">
      <div className="items-center w-full">
        <div className="flex flex-col items-start gap-y-[10px] w-full">
          <ModifierOptionPrice itemIndex={itemIndex} title={title} price={selectedOption?.price} />
          <Text className="sm:text-lg text-grey-60" variant="body2">
            {subTitle}
          </Text>
        </div>
      </div>
      <div className="flex overflow-x-scroll max-w-full gap-x-[15px]">
        <div className="flex flex-col items-center gap-y-[5px]">
          <button
            onClick={isLoading ? undefined : () => setSelectedOption(undefined)}
            className={classNames(
              "relative flex items-center justify-center hover:cursor-pointer border border-gray-300 rounded-lg h-[80px] w-[80px]",
              {
                "opacity-50": isLoading,
                "bg-primary-light": selectedOption === undefined,
                "border-none": !selectedOption,
              },
            )}
          >
            <Image src={banIcon.src} alt="ban icon" width={30} height={30} />
            {selectedOption === undefined && (
              <Image src={ImagesV2.checkCircleBlack} alt="check cirlce" className="absolute top-0 right-0" />
            )}
          </button>
          <Text
            className={classNames("text-center text-[12px]", { "!font-bold": selectedOption === undefined })}
            variant="caption"
          >
            {noOptionText}
          </Text>
        </div>
        {options?.map((option) => (
          <div className="flex flex-col items-center gap-y-[5px]" key={option?.optionId}>
            <button
              id={option?.optionId}
              disabled={option?.metadata?.soldOut}
              onClick={isLoading ? undefined : () => setSelectedOption(option as ProductModifierOption)}
              className={classNames(
                "relative flex items-center justify-center hover:cursor-pointer border border-gray-300 rounded-lg h-[80px] w-[80px] p-1",
                {
                  "opacity-50": isLoading,
                  "bg-primary-light": selectedOption?.optionId === option?.optionId && option?.image,
                  "border-none": !option?.image,
                },
              )}
            >
              <Image
                src={option?.image || ImagesV2.blackCircle}
                alt={option?.name}
                width={100}
                height={100}
                style={{ width: "100%", height: "auto" }}
              />
              {!option?.image && (
                <Text className="absolute text-center text-white text-wrap px-2 py-[12px]" variant="caption">
                  {option?.name}
                </Text>
              )}
              {option?.metadata?.soldOut && (
                <div className="absolute top-0 left-0 w-full h-full bg-secondary-light/80 rounded-lg flex items-center justify-center">
                  <Text variant="smallHeader">{t("order:out_of_stock")}</Text>
                </div>
              )}
              {option?.optionId === selectedOption?.optionId && (
                <Image
                  src={option?.image ? ImagesV2.checkCircleBlack : ImagesV2.checkCircleWhite}
                  alt="check circle"
                  className="absolute top-0 right-0"
                />
              )}
            </button>
            <Text
              variant="caption"
              className={classNames("text-center text-[12px]", {
                "!font-bold": selectedOption?.optionId === option?.optionId,
              })}
            >
              {option?.name}
            </Text>
          </div>
        ))}
      </div>
    </div>
  );
}

function ModifierOptionPrice({ itemIndex, title, price }) {
  const ctxRewards = useRewardsContext();
  const { t } = useTranslation();

  return (
    <div className="flex justify-between w-full">
      <Text variant="title2">
        {itemIndex}. {title}
      </Text>
      {price ? (
        <div>{t("order:giftwrap_cost", { cost: formatMoney(price) })}</div>
      ) : (
        price !== undefined && (
          <div className="flex gap-x-[5px]">
            (
            <Image
              src={ctxRewards?.rewardSummary?.currentTier?.badgeImageUrl}
              alt={`${ctxRewards?.rewardSummary?.currentTier?.badgeImageUrl}`}
              width={20}
              height={20}
            />
            <div>{t("order:free")}</div>)
          </div>
        )
      )}
    </div>
  );
}
