import type { SVGProps } from "react";

export default function IconTrashRegular(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M19 3.25H15.441C14.54 3.25 14.502 3.136 14.255 2.396L14.053 1.789C13.746 0.869001 12.889 0.25 11.919 0.25H8.08099C7.11099 0.25 6.253 0.868001 5.947 1.789L5.745 2.396C5.498 3.137 5.46 3.25 4.559 3.25H1C0.586 3.25 0.25 3.586 0.25 4C0.25 4.414 0.586 4.75 1 4.75H2.298L3.065 16.249C3.213 18.474 4.57701 19.75 6.80701 19.75H13.194C15.423 19.75 16.787 18.474 16.936 16.249L17.703 4.75H19C19.414 4.75 19.75 4.414 19.75 4C19.75 3.586 19.414 3.25 19 3.25ZM7.37 2.263C7.473 1.956 7.75799 1.75 8.08099 1.75H11.919C12.242 1.75 12.528 1.956 12.63 2.263L12.832 2.87C12.876 3.001 12.92 3.128 12.968 3.25H7.03C7.078 3.127 7.12301 3 7.16701 2.87L7.37 2.263ZM15.438 16.149C15.343 17.582 14.629 18.25 13.193 18.25H6.806C5.37 18.25 4.657 17.583 4.561 16.149L3.801 4.75H4.558C4.683 4.75 4.787 4.737 4.899 4.729C4.933 4.734 4.964 4.75 4.999 4.75H14.999C15.035 4.75 15.065 4.734 15.099 4.729C15.211 4.737 15.315 4.75 15.44 4.75H16.197L15.438 16.149ZM12.75 9V14C12.75 14.414 12.414 14.75 12 14.75C11.586 14.75 11.25 14.414 11.25 14V9C11.25 8.586 11.586 8.25 12 8.25C12.414 8.25 12.75 8.586 12.75 9ZM8.75 9V14C8.75 14.414 8.414 14.75 8 14.75C7.586 14.75 7.25 14.414 7.25 14V9C7.25 8.586 7.586 8.25 8 8.25C8.414 8.25 8.75 8.586 8.75 9Z"
        fill="currentColor"
        fillOpacity={props.fillOpacity || "1"}
      />
    </svg>
  );
}
