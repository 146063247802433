import type { SVGProps } from "react";
const IconMinusFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.8333 10.8333H4.16667C3.70667 10.8333 3.33334 10.46 3.33334 9.99996C3.33334 9.53996 3.70667 9.16663 4.16667 9.16663H15.8333C16.2933 9.16663 16.6667 9.53996 16.6667 9.99996C16.6667 10.46 16.2933 10.8333 15.8333 10.8333Z"
      fill="currentColor"
    />
  </svg>
);
export default IconMinusFilled;
