import type { SVGProps } from "react";
const IconCalendarClock = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M11 19.25H5c-1.577 0-2.25-.673-2.25-2.25V8.75h16.5V11a.75.75 0 0 0 1.5 0V6.5c0-2.418-1.332-3.75-3.75-3.75h-1.25V2a.75.75 0 0 0-1.5 0v.75h-6.5V2a.75.75 0 0 0-1.5 0v.75H5c-2.418 0-3.75 1.332-3.75 3.75V17c0 2.418 1.332 3.75 3.75 3.75h6a.75.75 0 0 0 0-1.5m-6-15h1.25V5a.75.75 0 0 0 1.5 0v-.75h6.5V5a.75.75 0 0 0 1.5 0v-.75H17c1.577 0 2.25.673 2.25 2.25v.75H2.75V6.5c0-1.577.673-2.25 2.25-2.25M8.02 12a1 1 0 0 1-1 1 1.005 1.005 0 0 1-1.005-1c0-.552.443-1 .995-1h.01a1 1 0 0 1 1 1m4 0a1 1 0 0 1-1 1 1.005 1.005 0 0 1-1.005-1c0-.552.443-1 .995-1h.01a1 1 0 0 1 1 1M18 13.25A4.756 4.756 0 0 0 13.25 18 4.756 4.756 0 0 0 18 22.75 4.756 4.756 0 0 0 22.75 18 4.756 4.756 0 0 0 18 13.25m0 8A3.254 3.254 0 0 1 14.75 18 3.254 3.254 0 0 1 18 14.75 3.254 3.254 0 0 1 21.25 18 3.254 3.254 0 0 1 18 21.25M8.02 16a1 1 0 0 1-1 1 1.005 1.005 0 0 1-1.005-1c0-.552.443-1 .995-1h.01a1 1 0 0 1 1 1m11.582 2.541a.75.75 0 0 1-1.06 1.061l-1.071-1.071a.75.75 0 0 1-.22-.53v-1.5a.75.75 0 0 1 1.5 0v1.189z"
    />
  </svg>
);
export default IconCalendarClock;
