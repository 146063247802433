import classNames from "classnames";
import { useEffect, useState } from "react";
import IconMinusFilled from "../../atoms/Icons/MinusFilled";
import IconPlusFilled from "../../atoms/Icons/PlusFilled";

interface QuantitySelectionButtonProps {
  quantity: number;
  onDecrement?: () => void;
  onIncrement?: () => void;
  iconForDecrement?: any;
  className?: string;
  onUpdateQuantity?: (quantity) => number;
  isLoading?: boolean;
}

export default function QuantitySelectionButton({
  quantity,
  onDecrement,
  onIncrement,
  iconForDecrement,
  className,
  isLoading = false,
}: QuantitySelectionButtonProps) {
  const [inputQuantity, setInputQuantity] = useState(quantity);

  useEffect(() => {
    //if the update is from the +/- buttons
    setInputQuantity(quantity);
  }, [quantity]);

  return (
    <div
      className={classNames(
        "flex-nowrap inline-flex items-center justify-between min-h-[40px] md:min-h-[50px] min-w-[110px] md:min-w-[159px] rounded-full py-3 bg-white text-black border-[1px] border-opacity-20",
        {
          "opacity-20": isLoading,
        },
        className,
      )}
    >
      <button className="p-2 md:p-4" onClick={onDecrement} data-testid="minus-button" disabled={isLoading}>
        {iconForDecrement || <IconMinusFilled />}
      </button>
      <p className="w-8 text-base text-nowrap font-bold text-center" data-testid="quantity">
        {inputQuantity}
      </p>
      <button
        className={classNames("p-2 md:p-4", { "text-gray-400": !onIncrement })}
        onClick={onIncrement}
        disabled={!onIncrement || isLoading}
        data-testid="plus-button"
      >
        <IconPlusFilled />
      </button>
    </div>
  );
}
