import type { SVGProps } from "react";
const IconBrandFacebookFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M22 12c0 4.99-3.66 9.13-8.44 9.88V15H16l.45-3h-2.89v-1.88c0-.79.39-1.56 1.63-1.56h1.26V6.19s-1.14-.2-2.24-.2c-2.28 0-3.78 1.29-3.78 3.8v2.2H8.01v3h2.42v6.88c-4.78-.75-8.44-4.89-8.44-9.88 0-5.52 4.48-10 10-10s10 4.48 10 10"
    />
  </svg>
);
export default IconBrandFacebookFilled;
