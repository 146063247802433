import { useRouter } from "next/router";
import { useEffect } from "react";

const Branch = () => {
  const router = useRouter();

  useEffect(() => {
    import("branch-sdk").then(({ default: BranchSDK }) => {
      BranchSDK.init(
        process.env.NEXT_PUBLIC_BRANCH_KEY || "key_test_mh77X34z1OTov2Gwdv07FphkFze9BzVR",
        null,
        (_, data) => {
          const parsed: { $deeplink_path?: string } = data?.data_parsed;
          const deeplink = parsed?.$deeplink_path;

          if (deeplink) {
            const referrer = data["~referring_link"];

            if (referrer) {
              const url = new URL(referrer);
              router.push(`${deeplink}${url.search}`);
            } else {
              router.push(`${deeplink}`);
            }
          }
        },
      );
    });
  }, []);

  return <></>;
};

export default Branch;
