import type { SVGProps } from "react";
const IconBrandLinkedinFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M7.04 21H3.25V9.63h3.79zm.38-15.79A2.212 2.212 0 0 0 3 5.15c-.03 1.22.93 2.24 2.15 2.27 1.24.01 2.25-.97 2.27-2.21m13.53 8.92c0-3.54-2.29-4.62-4.24-4.62-1.37-.04-2.66.63-3.41 1.77V9.64H9.64v11.37h3.79v-6.09c-.08-1.21.84-2.26 2.05-2.34.99 0 1.74.64 1.74 2.44v5.99h3.79l-.05-6.87z"
    />
  </svg>
);
export default IconBrandLinkedinFilled;
