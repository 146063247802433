import type { SVGProps } from "react";
const IconAlarmClockFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m5.53 3.53-2 2a.75.75 0 0 1-1.06 0 .75.75 0 0 1 0-1.061l2-2A.75.75 0 1 1 5.53 3.53m15.995.934-2-1.96a.751.751 0 0 0-1.05 1.072l2 1.96a.75.75 0 0 0 1.06-.01.75.75 0 0 0-.01-1.062m-4.922 14.073 1.929 1.934a.75.75 0 0 1-1.062 1.06l-2.218-2.225a7.96 7.96 0 0 1-6.5 0L6.533 21.53a.75.75 0 0 1-1.062-1.06L7.4 18.537A7.99 7.99 0 0 1 4.003 12a8 8 0 0 1 16 0 8 8 0 0 1-3.4 6.537M14.53 13.51l-1.78-1.78V8.041a.75.75 0 0 0-1.5 0v4c0 .199.079.39.22.53l2 2a.75.75 0 0 0 1.06 0 .75.75 0 0 0 0-1.061"
      clipRule="evenodd"
    />
  </svg>
);
export default IconAlarmClockFilled;
