import type { SVGProps } from "react";
const IconMenuLeftAltFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M16 7H3a1 1 0 0 1 0-2h13a1 1 0 0 1 0 2m6 5a1 1 0 0 0-1-1H3a1 1 0 0 0 0 2h18a1 1 0 0 0 1-1m-9 6a1 1 0 0 0-1-1H3a1 1 0 0 0 0 2h9a1 1 0 0 0 1-1"
    />
  </svg>
);
export default IconMenuLeftAltFilled;
