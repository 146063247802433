import type { SVGProps } from "react";
const IconBrandYoutubeFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M21.58 7.19c-.23-.86-.91-1.54-1.77-1.77C18.25 5 12 5 12 5s-6.25 0-7.81.42c-.86.23-1.54.91-1.77 1.77C2 8.75 2 12.01 2 12.01s0 3.26.42 4.82c.23.86.91 1.51 1.77 1.74 1.56.42 7.81.42 7.81.42s6.25 0 7.81-.42c.86-.23 1.54-.88 1.77-1.74.42-1.56.42-4.82.42-4.82s0-3.26-.42-4.82M10 15V9l5 3z"
    />
  </svg>
);
export default IconBrandYoutubeFilled;
