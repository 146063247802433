import { localeGTM } from "lib/locale-helpers";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";
import { useEffect } from "react";
import { track } from "static/lib/tracking";
import {
  AppStoreURL,
  fetchLocal,
  isChromeOnIOS,
  isIOS,
  onceEveryMinutes,
  preventRedirectBecauseOfPath,
} from "static/lib/util";
import Branch from "./OldBranch";
import { useUserTrackingConsentContext } from "./v2/contexts/UserTrackingConsentContext/UserTrackingConsentContext";

export enum LinkPreviewType {
  IMAGE = 0,
  VIDEO = 1,
}

const HeadAndMeta = ({
  overrideOpenGraphImage = null,
  overrideOpenGraphVideo = null,
  overrideOpenGraphDisplayType = null,
  overrideOpenGraphTitle = null,
  overrideOpenGraphDescription = null,
  storeName = null,
}) => {
  const ogImage =
    overrideOpenGraphImage || "https://crumbl.video/01f54f6f-7bd5-4fde-b5b9-b03448fb9d2a_crumbl-cookies.jpg";
  const linkPreviewType = overrideOpenGraphDisplayType == null ? LinkPreviewType.VIDEO : overrideOpenGraphDisplayType;
  const ogTitle =
    overrideOpenGraphTitle ||
    (storeName ? `Desserts in ${storeName} | Crumbl` : "Crumbl - Freshly Baked & Delivered Desserts");
  const ogDescription =
    overrideOpenGraphDescription ||
    (storeName
      ? `The best desserts in ${storeName}, made fresh daily. Fresh and gourmet desserts for takeout, delivery, or pick-up. Try our flavors of the week.`
      : "The best desserts in the world. Fresh and gourmet desserts for takeout, delivery, or pick-up. Made fresh daily. Unique and trendy flavors weekly.");

  const { locale } = useRouter();
  const { consent } = useUserTrackingConsentContext();
  const gtmId = localeGTM(locale);
  const ogVideo = overrideOpenGraphVideo || "https://images.crumbl.com/hype_sawyer_v2.mp4";

  const isClient = typeof window !== "undefined";
  useEffect(() => {
    if (!isClient) {
      return;
    }

    const phoneNumber = fetchLocal("phoneNumber") || "";
    const email = fetchLocal("email") || "";
    if (!phoneNumber && !email) {
      // nothing to report
      return;
    }

    track({
      event: "identify_user",
      ph: phoneNumber,
      em: email,
    });
  }, [isClient]);

  useEffect(() => {
    // Manage Google Analytics tracking
    // https://developers.google.com/analytics/devguides/collection/gajs/#disable
    const key = `ga-disable-${gtmId}`;
    window[key] = !consent?.marketing;
  }, [consent?.marketing]);

  return (
    <>
      <Head>
        <title>{ogTitle}</title>
        <link rel="shortcut icon" href="/favicons/favicon.ico" />
        <link rel="apple-touch-icon" sizes="180x180" href="/favicons/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicons/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicons/favicon-16x16.png" />
        <link rel="manifest" href="/favicons/site.webmanifest" />
        <link rel="mask-icon" href="/favicons/safari-pinned-tab.svg" color="#000000" />
        {isIOS() && !isChromeOnIOS() && onceEveryMinutes(20, "first_load") && !preventRedirectBecauseOfPath() && (
          <meta http-equiv="refresh" content={`0;url=${AppStoreURL}`} />
        )}
        <meta name="msapplication-TileColor" content="#000000" />
        <meta name="theme-color" content="#000000" />
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=5" />
        <meta property="og:title" content={ogTitle} />
        <meta name="title" content={ogTitle} />
        <meta property="og:description" content={ogDescription} />
        <meta name="description" content={ogDescription} />
        <meta property="og:url" content="https://crumblcookies.com" />
        <meta property="restaurant:restaurant" content="https://crumblcookies.com" />
        <meta property="og:type" content="restaurant.restaurant" />
        <meta property="og:image" content={ogImage} />
        {linkPreviewType === LinkPreviewType.VIDEO && <meta property="og:video" content={ogVideo} />}
        <meta property="fb:app_id" content="679984795749665" />
        <meta name="apple-itunes-app" content="app-id=1438166219" />
        <meta name="p:domain_verify" content="935484b661eb06b34a167f7c957218a2" />
        <meta name="google-site-verification" content="3L27R-1GSzE8A_xXuhUFHb3m6xSppM1PN8AfISfpVUg" />
      </Head>
      <Script
        id="google-maps-script"
        type="text/javascript"
        src={`https://maps.googleapis.com/maps/api/js?libraries=places&key=${process.env.NEXT_PUBLIC_GOOGLE_API_KEY}&callback=initMap`}
      />
      <Script
        id="gmapInit"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `function initMap(){}`,
        }}
      />
      {consent?.marketing && (
        <>
          <Branch />
          <Script
            id="google-tag-manager-script"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `(function(w, d, s, l, i) {
                w[l] = w[l] || [];
                w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
                var f = d.getElementsByTagName(s)[0],
                  j = d.createElement(s),
                  dl = l != "dataLayer" ? "&l=" + l : "";
                j.async = true;
                j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
                f.parentNode.insertBefore(j, f);
              })(window, document, "script", "dataLayer", "${gtmId}");`,
            }}
          />
        </>
      )}
    </>
  );
};

export default HeadAndMeta;
