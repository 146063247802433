import classNames from "classnames";
import { HTMLProps, ReactNode } from "react";
import type { TextVariant } from "../../atoms/Text/Text";
import Text from "../../atoms/Text/Text";

const variants = {
  underline: "underline"
} as const;

export type ButtonVariant = keyof typeof variants;
export const allVariants = Object.keys(variants) as ButtonVariant[];

interface ButtonProps extends HTMLProps<HTMLButtonElement> {
  variant?: keyof typeof variants;
  contentLeft?: ReactNode;
  contentRight?: ReactNode;
  block?: boolean;
  height?: number;
  textVariant?: TextVariant;
}

export default function ButtonText({
  disabled = false,
  style,
  children,
  onClick,
  className = "",
  variant = "underline",
  contentLeft,
  contentRight,
  block,
  height,
  textVariant
}: ButtonProps) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={classNames(
        variants[variant],
        contentLeft ? "pl-[25px]" : "pl-0",
        contentRight ? "pr-[25px]" : "pr-0",
        contentLeft && contentRight ? "justify-between" : "justify-center",
        block ? "w-full" : "w-fit",
        className
      )}
      style={style}
    >
      {contentLeft && <span className="mr-2">{contentLeft}</span>}
      <Text variant={textVariant} className={classNames("text-base text-nowrap font-bold")}>
        {children}
      </Text>
      {contentRight && <span className="ml-2">{contentRight}</span>}
    </button>
  );
}
