import type { SVGProps } from "react";
import React from "react";

const IconTrophyStarRegular = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M20 4.5H18.5C18.5 3.397 17.603 2.5 16.5 2.5H7.5C6.397 2.5 5.5 3.397 5.5 4.5H4C3.173 4.5 2.5 5.173 2.5 6V7.65002C2.5 10.114 4.43299 11.306 5.98999 11.467C6.90299 13.683 9.007 15.283 11.5 15.474V17.534C9.542 17.706 8.5 18.886 8.5 20.999V21.499H15.5V21C15.5 18.887 14.458 17.707 12.5 17.535V15.475C14.994 15.284 17.097 13.684 18.01 11.468C19.567 11.307 21.5 10.115 21.5 7.651V6C21.5 5.173 20.827 4.5 20 4.5ZM3.5 7.65002V6C3.5 5.724 3.725 5.5 4 5.5H5.5V9C5.5 9.478 5.55505 9.94303 5.65405 10.392C4.69305 10.134 3.5 9.35002 3.5 7.65002ZM14.476 20.5H9.52502C9.66802 19.122 10.448 18.5 12.001 18.5C13.554 18.5 14.333 19.122 14.476 20.5ZM12.004 14.5C12.003 14.5 12.001 14.499 12 14.499C11.999 14.499 11.998 14.5 11.996 14.5C8.96597 14.498 6.5 12.032 6.5 9V4.5C6.5 3.948 6.948 3.5 7.5 3.5H16.5C17.052 3.5 17.5 3.948 17.5 4.5V9C17.5 12.031 15.034 14.498 12.004 14.5ZM20.5 7.65002C20.5 9.35002 19.3069 10.134 18.3459 10.392C18.4439 9.94303 18.5 9.478 18.5 9V5.5H20C20.275 5.5 20.5 5.724 20.5 6V7.65002ZM14.785 7.07397L13.377 6.87L12.769 5.64301C12.478 5.05401 11.5231 5.05503 11.2321 5.64203L10.624 6.87L9.21594 7.07397C8.89994 7.11997 8.64099 7.33703 8.54199 7.64203C8.44299 7.94603 8.52403 8.27297 8.75403 8.49597L9.77197 9.48297L9.54102 10.828C9.48402 11.151 9.61403 11.472 9.87903 11.666C10.145 11.86 10.492 11.886 10.783 11.734L12.002 11.096L13.2209 11.734C13.3479 11.8 13.485 11.833 13.62 11.833C13.798 11.833 13.974 11.777 14.125 11.667C14.39 11.473 14.52 11.152 14.464 10.83L14.2321 9.48297L15.25 8.49597C15.479 8.27297 15.561 7.94603 15.462 7.64203C15.36 7.33803 15.102 7.11997 14.785 7.07397ZM13.4709 8.82703C13.2729 9.01903 13.183 9.29502 13.229 9.56702L13.427 10.715L12.387 10.171C12.145 10.045 11.856 10.045 11.614 10.171L10.574 10.715L10.772 9.56598C10.818 9.29498 10.728 9.01903 10.531 8.82703L9.69397 8.01599L10.851 7.84802C11.123 7.80902 11.358 7.64001 11.481 7.39301L12.001 6.34399L12.522 7.39301C12.644 7.63901 12.878 7.80798 13.15 7.84698L14.308 8.01501L13.4709 8.82703Z"
      fill="currentColor"
    />
  </svg>
);
export default IconTrophyStarRegular;
