import type { SVGProps } from "react";
const IconShoppingBagAltFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="m20.984 17.715-.667-6.999C20.17 9.176 18.999 8 17.331 8h-.58V7c0-2.62-2.13-4.75-4.75-4.75S7.251 4.38 7.251 7v1h-.58c-1.668 0-2.84 1.176-2.986 2.716l-.667 6.999C2.85 19.476 4.004 21 6.004 21h11.997c1.997 0 3.151-1.523 2.983-3.285M8.75 7c0-1.79 1.46-3.25 3.25-3.25S15.25 5.21 15.25 7v1h-6.5z"
    />
  </svg>
);
export default IconShoppingBagAltFilled;
