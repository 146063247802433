import { useEffect, useRef } from "react";

export const useClickOff = <TRef extends HTMLElement = HTMLElement>(onClickOff: () => void) => {
  const ref = useRef<TRef>(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOff();
      }
      event.stopPropagation();
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClickOff]);

  return ref;
};
