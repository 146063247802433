import type { SVGProps } from "react";
const IconBrandInstagramFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M20.94 8.29c-.07-1.44-.4-2.72-1.45-3.77s-2.33-1.38-3.77-1.45c-1.49-.08-5.94-.08-7.42 0-1.44.07-2.71.4-3.77 1.45S3.15 6.85 3.08 8.29c-.08 1.49-.08 5.94 0 7.43.07 1.44.4 2.72 1.45 3.77 1.06 1.05 2.33 1.38 3.77 1.45 1.49.08 5.94.08 7.42 0 1.44-.07 2.72-.4 3.77-1.45s1.38-2.33 1.45-3.77c.08-1.49.08-5.94 0-7.42zM12 16.75c-2.62 0-4.75-2.13-4.75-4.75S9.38 7.25 12 7.25s4.75 2.13 4.75 4.75-2.13 4.75-4.75 4.75m5.52-9.25c-.55 0-1-.45-1-1s.44-1 1-1c.55 0 1 .45 1 1s-.45 1-1 1M15.25 12c0 1.79-1.46 3.25-3.25 3.25S8.75 13.79 8.75 12 10.21 8.75 12 8.75s3.25 1.46 3.25 3.25"
    />
  </svg>
);
export default IconBrandInstagramFilled;
